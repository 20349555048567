import React, { useEffect } from 'react'
import WebContainer from '../../components/webcontainer'
import SocialProof from '../../components/socialproof'
import WhatIsCb from '../../components/whatiscb'
import PrivacyFirst from '../../components/privacyfirst'
import ForEnterprise from '../../components/forenterprise'
import { Row, Col, Icon, Button, Card } from 'antd'
import shareImage from '../../images/sync_share_image.png'
import {
  LinkedinOutlined,
  MenuOutlined,
  TwitterOutlined,
  YoutubeOutlined,
} from '@ant-design/icons'

import Logo from '../../images/svg/new_CB_logo.svg'

import LogoLight from '../../images/svg/logo-light.svg'

import Shield from '../../images/svg/bg-shield.svg'
import Hero1 from '../../images/hero1-sshot.png'

import HeroAnimated from '../../images/svg/full_animation.svg'
import Brands from '../../images/brands.png'
import Scheduler from '../../images/svg/scheduler-brand.svg'
import Sync from '../../images/svg/sync-brand.svg'
import SEO from '../../components/SEO'
import { Link } from 'gatsby'
import { getAuthState } from '../../utils/auth'

import WebsiteFooter from '../../components/WebsiteFooter'

import Highlight from '../../components/Highlight'
import WebsiteHeader from '../../components/WebsiteHeader'
const Index = ({ data }) => {
  const authState = getAuthState()

  // <div className="relative">
  //       <span className="absolute">{label}</span>
  //       <div className={`mt-9 w-${w} h-4 bg-${color}`}></div>
  //     </div>

  const Section = ({ title, content }) => {
    return (
      <div
        className="flex flex-col lg:flex-row  justify-center w-full mb-0 lg:mb-8 "
        style={{ maxWidth: 1200, zIndex: 1 }}
      >
        <div className="flex justify-center lg:justify-start lg:w-1/3 w-full ">
          <div className="font-lato text-brand_dark_blue text-5xl flex flex-row  mt-4 lg:mb-0 mb-12">
            {title}
          </div>
        </div>
        <div className="flex lg:w-2/3 w-full justify-center  lg:justify-start">
          {content}
        </div>
      </div>
    )
  }

  // const graphqlResult = useStaticQuery(graphql/* GraphQL */ `
  //   query WpPosts {
  //     # Query all WordPress blog posts sorted by date
  //     allWpPost(sort: { fields: [date], order: DESC }) {
  //       edges {
  //         previous {
  //           id
  //         }

  //         # note: this is a GraphQL alias. It renames "node" to "post" for this query
  //         # We're doing this because this "node" is a post! It makes our code more readable further down the line.
  //         post: node {
  //           id
  //           uri
  //           date(formatString: "MMMM DD, YYYY")
  //           title
  //           excerpt
  //         }

  //         next {
  //           id
  //         }
  //       }
  //     }
  //   }
  // `)

  function renderWhatIs() {
    return (
      <div className="lg:ml-2 h-full flex flex-col justify-center ">
        <div className="flex flex-col lg:flex-row items-center">
          <div className="w-2/3 lg:w-2/4 lg:mr-8">
            <div className="mb-4">
              <h2 className="font-lato text-lg text-brand_dark_blue">Sync Calendars Together in Real-Time</h2>
            </div>
            <div className="font-roboto  text-lg mb-4">
              CalendarBridge is the only cloud-based solution that syncs
              calendars in real-time and supports Google Calendar and Microsoft
              365. Merge availability of two or more Google calendars, Outlook calendars, or a combination of Google and Outlook calendars.
            </div>
            <Link
              to="/sync"
              className="cursor-pointer font-roboto underline text-brand_blue hover:text-brand_dark_blue"
            >
              Learn More About Sync
            </Link>
          </div>
          <div className="flex justify-center lg:w-1/2">
            <Sync className="w-2/3 lg:w-3/4"></Sync>
          </div>
        </div>
        <div className="flex flex-col lg:flex-row items-center">
          <div className="flex justify-center lg:justify-start lg:w-1/2 order-2 lg:order-1">
            <Scheduler className="w-2/3 lg:w-3/4"></Scheduler>
          </div>
          <div className="w-2/3 lg:w-1/2 ml-8 order-1 lg:order-2">
            <div className="font-lato lg:text-lg text-brand_dark_blue mb-4">
            <h2 className="font-lato text-lg text-brand_dark_blue">Schedule Meetings Based on Multiple Calendars</h2>
            </div>
            <div className="font-roboto mb-4">
              Schedule meetings by simply sharing a link to your availability.
              Our schedulers natively support checking availability on up to 4 calendars. When combined with syncing, each scheduler can account for even more calendars.
            </div>
            <Link
              to="/schedule"
              className="cursor-pointer font-roboto underline text-brand_blue hover:text-brand_dark_blue"
            >
              Learn More About Scheduler
            </Link>
          </div>
        </div>
      </div>
    )
  }
  const isBrowser = typeof document !== 'undefined'

  function stripHtml(html) {
    // Create a new div element
    if (isBrowser) {
      let temporalDivEl = document.createElement('div')
      // Set HTML content using provider
      temporalDivEl.innerHTML = html
      // Get the text property of the element (browser support)
      return temporalDivEl.textContent || temporalDivEl.innerText || ''
    } else {
      return ''
    }
  }

  const SectionHero = ({}) => {
    return (
      <div
        className="flex flex-col lg:flex-row items-center justify-start h-full  w-full "
        style={{ maxWidth: 1200 }}
      >
        <div className="w-full lg:w-1/3 text-white  flex flex-col lg:order-1 order-2">
          <div className="flex font-lato text-brand_dark_blue text-5xl lg:text-5xl space-x-1 lg:space-x-0 lg:flex-col  mb-4 justify-center lg:justify-start">
            <div className="flex flex-row">
              Protect Your <br /> Time & Privacy
            </div>
          </div>
          <div className="flex flex-col ">
            <div className="text-text_website text-md lg:w-90 text-justify pl-4 pr-4 lg:pl-0 lg:pr-0 lg:text-left font-roboto">
              CalendarBridge provides{' '}
              <strong>
                automatic, real-time Outlook & Google calendar syncing
              </strong>{' '}
              with advanced privacy controls. All plans also include scheduling links{' '}
              <strong>built for people with multiple calendars</strong>.
            </div>

            <div className="flex justify-center lg:justify-start lg:w-80">
              <Link
                className="text-md text-center rounded-md pl-4 pr-4 pt-2 pb-2 mt-6 mb-5 hover:bg-burnt bg-dark_burnt text-white lg:w-80"
                to="/signup?referral=homepage"
              >
                Try CalendarBridge For Free
              </Link>
            </div>
            <div className="text-text_website text-md text-center lg:text-left font-roboto">
              Already a user? <Link to="/dashboard">Access your dashboard</Link>
            </div>
          </div>
        </div>
        <div className="flex w-full justify-center lg:w-2/3 lg:order-2 order-1 lg:h-96">
          <div>
            <HeroAnimated width="100%" height="100%"></HeroAnimated>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div>
      <SEO
        title="CalendarBridge | Sync All Your Calendars"
        description="Welcome to the only cloud-based solution that syncs digital calendars in real-time and supports Google Calendar and Microsoft 365."
        image={shareImage}
      />
      <div className="flex flex-col justify-center items-center text-lg">
        <div className="w-full bg-creme grid grid-cols-1">
          <WebsiteHeader authState={authState}></WebsiteHeader>
          <div className="flex justify-center mt-5 lg:mt-10 mb-10 text-lg">
            <SectionHero></SectionHero>
          </div>
        </div>

        <Section
          title={
            <div className="mt-8 text-2xl font-lato lg:text-5xl">
              <span>
                What is{' '}
                <Highlight
                  label="CalendarBridge?"
                  color="creme"
                  w={80}
                ></Highlight>
              </span>
            </div>
          }
          content={renderWhatIs()}
        ></Section>
        

        <div className="flex bg-brand_dark_blue w-full items-center justify-center justify-items-center flex-col pt-8 pb-8">
          {/* <div className="flex flex-col justify-center justify-items-center self-center justify-self-center"> */}
          <Section
            title={
              <div className="font-lato text-white text-2xl lg:text-5xl flex flex-col justify-center lg:justify-left lg:text-left text-center">
                Busy people love{' '}
                <Highlight
                  label="CalendarBridge"
                  color="brand_blue"
                ></Highlight>
              </div>
            }
            content={
              <div className="lg:ml-2 grid grid-cols-1 pl-2 pr-2 lg:pl-0 lg:pr-0 font-bold lg:grid-cols-2 gap-2  lg:mt-8 items-center self-center place-self-center justify-center justify-items-center">
                <div className="w-full bg-white rounded-xl grid grid-cols-1 items-center justify-center shadow-sm">
                  <div className="text-brand_blue text-lg pl-2 pr-2 pt-6 pb-3 text-center font-bold">
                    “Finally a sync software that is easy, inexpensive, and
                    works!”
                  </div>
                  <div className="rounded-b-xl p-4 pt-2 flex flex-row items-center justify-center">
                    <div className="font-bold w-1/3 text-center">Justin S.</div>
                    <div className="text-xs">
                      <div>Owner</div>
                      <div>Financial Services, Self-employed</div>
                    </div>
                  </div>
                </div>

                <div className="w-full bg-white rounded-xl grid grid-cols-1 items-center justify-center shadow-sm">
                  <div className="text-brand_blue text-lg pl-2 pr-2 pt-6 pb-3 text-center font-bold">
                    “The absolute best multi-directional multi-calendar sync”
                  </div>
                  <div className="rounded-b-xl p-4 pt-2 flex flex-row items-center justify-center">
                    <div className="font-bold w-1/3 text-center">Judah J.</div>
                    <div className="text-xs">
                      <div>Principal</div>
                      <div>IT and Services, 1-10 employees</div>
                    </div>
                  </div>
                </div>
                <div className="bg-white w-full rounded-xl grid grid-cols-1 items-center justify-center shadow-sm">
                  <div className="text-brand_blue text-lg pl-2 pr-2 pt-6 pb-3 text-center font-bold">
                    “The best thing I've found in 2020”<p></p>
                  </div>
                  <div className="rounded-b-xl p-4 pt-2 flex flex-row items-center justify-center">
                    <div className="font-bold w-1/3 text-center">
                      Brendan S.
                    </div>
                    <div className="text-xs">
                      <div>Operations & Enablement</div>
                      <div>Telecommunications, 1001-5000 employees</div>
                    </div>
                  </div>
                </div>
                <div className="w-full bg-white rounded-xl grid grid-cols-1 items-center justify-center shadow-sm">
                  <div className="text-brand_blue text-lg pl-2 pr-2 pt-6 pb-3 mb-4 text-center font-bold">
                    “Saved me from calendar madness!”
                    <p></p>
                  </div>
                  <div className="rounded-b-xl p-4 pt-2 flex flex-row items-center justify-center">
                    <div className="font-bold w-1/3 text-center">Lucas D.</div>
                    <div className="text-xs">
                      <div>Chief Operating Officer</div>
                      <div>E-Learning, 11-50 employees</div>
                    </div>
                  </div>
                </div>
              </div>
            }
          ></Section>
        </div>
        <div className=" bg-white pt-24 flex w-full flex-col items-center justify-center justify-items-center">
          <Section
            title={
              <div className=" bg-white">
                <div className="font-lato text-brand_dark_blue text-2xl lg:text-5xl flex flex-row">
                  <div>
                    Trusted by{' '}
                    <Highlight label="global" color="creme" w={32}></Highlight>{' '}
                    organizations
                  </div>
                  {/* <div className="relative">
                <span className="absolute">new</span>
                <div className="mt-8 w-24 h-4 bg-creme"></div>
              </div> */}{' '}
                </div>
              </div>
            }
            content={
              <div className="pl-3 pr-2  lg:pr-0 mb-16 lg:mb-0">
                <img width="600" src={Brands}></img>
              </div>
            }
          ></Section>

          <Section
            title={
              <div className="bg-white">
                <div className="font-lato text-brand_dark_blue text-2xl lg:text-5xl flex flex-col">
                  <div>
                    Made{' '}
                    <Highlight label="for you" color="creme" w={20}></Highlight>{' '}
                  </div>
                  {/* <div className="relative">
                <span className="absolute">new</span>
                <div className="mt-8 w-24 h-4 bg-creme"></div>
              </div> */}{' '}
                </div>
              </div>
            }
            content={
              <div className="lg:ml-1 grid lg:grid-cols-3 grid-cols-1 lg:space-x-8 text-center lg:text-left justify-center items-center">
                <div className="flex flex-col justify-between h-full">
                  <div className="font-lato text-lg text-brand_dark_blue mb-2">
                    There’s nothing to install.
                  </div>
                  <div
                    className="font-roboto text-brand_dark_blue text-center lg:text-left p-2 lg:p-0"
                    style={{ height: 168 }}
                  >
                    Simply customize your settings and our cloud-based
                    technology will take care of the rest.{' '}
                  </div>
                </div>
                <div className="flex flex-col justify-between h-full">
                  <div className="font-lato text-lg text-brand_dark_blue mb-2">
                    We provide expert security.
                  </div>
                  <div
                    className="font-roboto text-brand_dark_blue p-2 lg:p-0"
                    style={{ height: 168 }}
                  >
                    Our background in cybersecurity means we understand
                    enterprise-grade protection and implement it every day.
                  </div>
                </div>
                <div className="flex flex-col justify-between h-full">
                  <div className=" font-lato text-lg text-brand_dark_blue mb-2 ">
                    Real-time availability makes scheduling easy.{' '}
                  </div>
                  <div className="font-roboto text-brand_dark_blue p-2 lg:p-0">
                    Because CalendarBridge syncs all of your calendars across
                    all domains and platforms, your availability is always
                    accurate and up-to-date.
                  </div>
                </div>
              </div>
            }
          ></Section>
        </div>

        <div className="flex flex-col bg-brand_blue w-full items-center justify-center justify-items-center pt-8 pl-2 lg:pl-8">
          <Section
            title={
              <div className="mt-12 text-white font-lato  text-3xl lg:text-5xl flex lg:flex-col flex-row">
                <div className=" flex flex-row">
                  <div>Your </div>
                  <div className="w-2"></div>
                  <Highlight
                    label="privacy"
                    color="brand_dark_blue"
                    w={32}
                  ></Highlight>{' '}
                </div>
                <div className="ml-2 lg:ml-0">comes first</div>
              </div>
            }
            content={
              <div className="flex flex-col items-center p-2 lg:p-0 lg:mt-20 lg:w-96 font-lato">
                <div>
                  <div>
                    <div className="text-white mb-4 font-roboto">
                      Our goal is simplicity in calendaring – not hoarding your
                      personal information. That’s why we never store or analyze any of
                      your calendar data.{' '}
                    </div>

                    <div className="text-white font-roboto mb-2">
                      CalendarBridge never views or stores your Google or
                      Microsoft login credentials, does not stores details of
                      your calendar events, and has no access to your emails.
                    </div>
                    <Link
                      to="https://help.calendarbridge.com/calendarbridge-security/"
                      className="text-white underline bold hover:text-brand_dark_blue hover:underline"
                    >
                      More about our privacy and security
                    </Link>
                  </div>
                </div>
              </div>
            }
          ></Section>

          <Section
            title={
              <div className="text-3xl items-center text-center lg:text-left lg:text-5xl font-lato mt-12 text-white ">
                <Highlight
                  label="Enterprise ready"
                  color="brand_dark_blue"
                  w={48}
                ></Highlight>{' '}
                calendar solution
              </div>
            }
            content={
              <div className="flex flex-col lg:mt-20 font-lato">
                <div className="lg:w-96 p-2 lg:p-0">
                  <div>
                    <div className="text-white mb-4 font-roboto">
                      In addition to our best-in-class security and privacy
                      practices, we offer IT adminstrators all the tools they
                      need to manage CalendarBridge for teams of all sizes.
                      Manage billing, licenses, and custom privacy controls all
                      from our intuitive admin portal.
                    </div>
                    <Link
                      to="https://help.calendarbridge.com/doc/group-account-management/managed-syncs-vs-managed-users/"
                      className="text-white underline bold hover:text-brand_dark_blue hover:underline"
                    >
                      Learn more about our admin tools
                    </Link>
                  </div>
                </div>
              </div>
            }
          ></Section>

          <div style={{ position: 'absolute', right: 0, fillOpacity: 0.3 }}>
            <div className="flex-grow"></div>
            <Shield></Shield>
          </div>
        </div>
        <WebsiteFooter></WebsiteFooter>
      </div>
    </div>
  )
}

export default Index

const styles = {
  heroHeading: {
    marginBottom: '0.6em',
    fontSize: '1.5em',
    color: '#09467D',
  },
  card: {
    backgroundColor: 'white',
    boxShadow:
      '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
    margin: '8px 8px',
    padding: '30px 20px 100px 20px',
    borderRadius: '5px',
  },
}
